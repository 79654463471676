import React from "react"

const Footer = () => (
  <footer
    style={{
      // position: `absolute`,
      bottom: 0,
      margin: `0 auto`,
      maxWidth: 960,
      padding: `1.2rem 4rem`,
      height: `60px`,
      background: `#0A75AB`,
      color: `#fff`
    }}
  >
    <h4>
      © {new Date().getFullYear()}, All rights reserved{` `}
      <a href="https://www.snorph.com" className="footerLink">Snorph</a>
    </h4>
  </footer>
)

export default Footer
