import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Image from './image';

const Header = ({ siteTitle }) => ( 
  <header
    style={{
      background: `white`,
      marginBottom: `1.45rem`,
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        // padding: `1.45rem 1.0875rem`,
      }}
    >
      <div style={{ margin: 0, padding: 5 }}>
        <Link to="/" title="home">
          <div style={{maxWidth: `100px`}} title={siteTitle}>
            <Image index={1} />
          </div>
        </Link>
        <br />
        <ul id="menu">
          <li><Link to="/">Home</Link></li>
          <li><a href="https://teespring.com/stores/snorph-brand" target="_blank" rel="noopener noreferrer">Store</a></li>
          <li><Link to="/blog">Blog</Link></li>
          <li><Link to="/about">About</Link></li>
        </ul>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
